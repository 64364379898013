{
  "root": "src/",
  "token_limit": 8000,
  "mode": "APP",
  "fundraiserURL": "",
  "global": {
    "opener": ""
  },
  "about": "Cat GPT is a cat toy experiment created by <a href='https://pda.nyc' target='_blank' >pda.nyc</a> as a gift for the internet’s cat lovers, and anyone remotely interested in AI. \n\nplease enjoy. meow!",
  "intro": "",
  "unity": {
    "buildUrl": "/unity/catGpt202303072132/Build",
    "loaderUrl": "/catGpt202303072132.loader.js",
    "dataUrl": "/6a7085c2ec78068f14a548cfe3dd85bd.data.br",
    "frameworkUrl": "/edd7dc8c41a24ef6fd6850567631042e.js.br",
    "codeUrl": "/e2677b73d70912c364878d3ef3f1b73b.wasm.br",
    "streamingAssetsUrl": "StreamingAssets",
    "companyName": "DefaultCompany",
    "productName": "CatDate",
    "productVersion": "0.1",
    "doNotCaptureKeyboard": true
  },
  "chat": [
    "So how about the weather lately? It’s prime sunspot time.",
    "Ummm. So, really good fish this time of year, huh?",
    "So, have you seen this new cat tree design with the dangling ball toy?",
    "So what’s your stance on the wobbling fish toy vs catnip banana debate?",
    "I’d like to discuss moving my cat bed to the other side of the room. It would be much more feng shui.",
    "Did you find the gift I left for you on your pillow yet? Dead mouse just screams ‘you.’",
    "The music selection at this restaurant really sets the vibe.",
    "Soooo, do you change your own human litter or does someone come and do it for you too?",
    "So is this what you do when you leave me alone in the house all day?",
    "Have you ever tried sleeping on your laptop? It’s pretty amazing.",
    "Why do you make biscuits on the computer with your human paws?",
    "I was thinking…you could put a little more effort into the presentation of my food. I see how you plate yours.",
    "Do you ever just think about murdering small animals? I do all the time.",
    "Don’t you think it’s kind of unfair that you can turn the faucet on whenever you want?"
  ],
  "audio": {
    "lobby": [
      "/audio/endless_love.mp3",
      "/audio/hero.mp3",
      "/audio/hellllo.mp3",
      "/audio/can_t_help_falling_in_love.mp3",
      "/audio/es_todo_mi_amor_-_caro_luna.mp3"
    ],
    "chat": [
      "/audio/i-want-to-know-what-love-is-2017-remaster.mp3"
    ]
  },
  "pp": ""
}