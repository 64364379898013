import { useEffect, useRef, useState } from "react";
import { AppSession } from "../utils/store";

export const DEFAULT_VOLUME = 0.05;

const AudioPlayer = props => {

  const audioRef = useRef(null);
  const [status, setStatus] = useState(false);
  const [track, setTrack] = useState(0);
  const { tracks } = props;
  const { clicks, setClicks } = useState(0);

  const handleCLick = () => {
    if (AppSession.clicks === 0 && audioRef.current) {
      AppSession.clicks = 1;
      audioRef.current.play();
      gsap.fromTo(AppSession.audioPlayer, {
        volume: 0
      }, {
        volume: DEFAULT_VOLUME,
        ease: 'none',
        duration: 10
      });
    }
  }

  useEffect(() => {
    if (status === 'ended') {
      const next = track + 1 === tracks.length ? 0 : track + 1;
      setTrack(next);
      audioRef.current.src = tracks[next];
      audioRef.current.play();
    } else if (status === 'play') {
      gtag('event', 'audio', 'play');
      gsap.fromTo(AppSession.audioPlayer, {
        volume: 0
      }, {
        volume: DEFAULT_VOLUME
      });
    } else if (status === 'pause') {
    }
  }, [status])

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = tracks[track];
      audioRef.current.volume = DEFAULT_VOLUME;
      audioRef.current.addEventListener('pause', () => {
        gtag('event', 'audio', 'paused');
      });
      audioRef.current.addEventListener('play', () => {
        gtag('event', 'audio', 'play');
      });
      AppSession.audioPlayer = audioRef.current;
      document.addEventListener('click', handleCLick.bind(AppSession.audioPlayer))
    }
  }, [audioRef])

  return <audio ref={audioRef} volume={0}
    onPlay={e => { setStatus('play') }}
    onPause={e => { setStatus('pause') }}
    onEnded={e => { setStatus('ended') }}
  ></audio>
}

export default AudioPlayer;