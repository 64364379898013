import { go } from "../utils/navigate";
import AbstractView from "./AbstractView"
import React from 'react';
import Badge from '../../assets/heart-badge.svg'
import { useState } from 'react';
import { AppSession, UserSession } from '../utils/store';
import { typeIn } from '../utils/animation';
import { EVENT_TYPES, trackEvent } from "../utils/analytics";

export const COLORS = {
  BLACK: 'black',
  BLACKWHITE: 'black-white',
  WHITE: 'white',
  ORANGE: 'orange',
  BROWN: 'brown',
  GREY: 'gray',
}

export default class Appearance extends AbstractView {
  state = { color: undefined }

  constructor(props) {
    super(props);
    this.section = React.createRef();
  }

  componentDidMount() {
    const h3 = this.section.current.querySelector('.appearance__text h3');
    typeIn(h3);
  }

  render() {

    const handleSwatchSelect = colorCode => {
      const color = COLORS[colorCode];
      if (color === this.state.color) {
        this.setState({ color: undefined });
      } else {
        this.setState({ color: colorCode });
        UserSession.color = colorCode;
        if (AppSession.unityApp) {
          AppSession.unityApp.SendMessage('JSListener', 'ReceiveMaterial', UserSession.color);
        }
      }
    }

    return <section ref={this.section} className="appearance">
      <h5>tell us about your date</h5>
      <div className="appearance__wrapper">
        <div className="appearance__text">
          <h3>what’s<br />your cat<br />look like?</h3>
        </div>
        <div className="appearance__colors">

          <ul className="appearance__colors-list">
            {Object.keys(COLORS).map(color => (
              <li key={color} onClick={(e) => {
                handleSwatchSelect(color);
              }} className={`appearance__swatch appearance__swatch--${COLORS[color]} ${color === this.state.color && 'appearance__swatch--selected'}`}>
                <Badge />
              </li>
            ))}
          </ul>
          <div className="controls">
            <button
              onClick={e => {
                if (this.state.color === undefined) return;
                trackEvent(EVENT_TYPES.CONFIG, {
                  type: 'color',
                  color: this.state.color
                });
                go('personality');
              }}
              className={`button ${this.state.color === undefined ? 'button--disabled' : ''}`}>next</button>
          </div>
        </div>
      </div>
    </section>
  }
}