import { UserSession } from "./store";

import affectionateAll from '../../../davinci/affectionate-all.json';
import affectionateCat from '../../../davinci/affectionate-cat.json';
import affectionateUser from '../../../davinci/affectionate-user.json';
import affectionateDefault from '../../../davinci/affectionate-default.json';

import mischievousAll from '../../../davinci/mischievous-all.json';
import mischievousCat from '../../../davinci/mischievous-cat.json';
import mischievousUser from '../../../davinci/mischievous-user.json';
import mischievousDefault from '../../../davinci/mischievous-default.json';

import highMaintenanceAll from '../../../davinci/high-maintenance-all.json';
import highMaintenanceCat from '../../../davinci/high-maintenance-cat.json';
import highMaintenanceUser from '../../../davinci/high-maintenance-user.json';
import highMaintenanceDefault from '../../../davinci/high-maintenance-default.json';

import skittishAll from '../../../davinci/skittish-all.json';
import skittishCat from '../../../davinci/skittish-cat.json';
import skittishUser from '../../../davinci/skittish-user.json';
import skittishDefault from '../../../davinci/skittish-default.json';
import { pickRandom } from "..";
import { PERSONALITIES } from "../views/Personality";
import { getDialog } from "../views/Chat";

const MODEL = "gpt-3.5-turbo-instruct";

export const dialogs = {
  'affectionate-all': affectionateAll,
  'affectionate-cat': affectionateCat,
  'affectionate-user': affectionateUser,
  'affectionate-default': affectionateDefault,
  'mischievous-all': mischievousAll,
  'mischievous-cat': mischievousCat,
  'mischievous-user': mischievousUser,
  'mischievous-default': mischievousDefault,
  'skittish-all': skittishAll,
  'skittish-cat': skittishCat,
  'skittish-user': skittishUser,
  'skittish-default': skittishDefault,
  'high maintenance-all': highMaintenanceAll,
  'high maintenance-cat': highMaintenanceCat,
  'high maintenance-user': highMaintenanceUser,
  'high maintenance-default': highMaintenanceDefault,
}

const apiCall = data => (
  fetch("https://api.openai.com/v1/completions", {
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${process.env.API_KEY}`,
      "Content-Type": "application/json"
    },
    method: "POST"
  }).then((response) => response.json())
)

export const chat = {
  connect: () => {

    let personality = UserSession.personality;

    if (personality === '') {
      personality = pickRandom(PERSONALITIES, true);
    }

    let prompt = getDialog(personality)
      .replaceAll('[name]', UserSession.name)
      .replaceAll('[catName]', UserSession.catName);

    UserSession.chat.messages = prompt;

    const data = {
      "model": MODEL,
      "prompt": prompt,
      "temperature": 0.9,
      "max_tokens": 256,
      "top_p": 1,
      "frequency_penalty": 0.15,
      "presence_penalty": 0.15,
      "stop": ["USER:", "\nUSER:"]
    };

    return apiCall(data);
  },

  send: (prompt) => {

    UserSession.chat.messages += "\nUSER: " + prompt + '\n';

    const data = {
      "model": MODEL,
      "prompt": UserSession.chat.messages,
      "temperature": 0.9,
      "max_tokens": 256,
      "top_p": 1,
      "frequency_penalty": 0.15,
      "presence_penalty": 0.15,
      "stop": ["USER:", "\nUSER:"]
    };
    return apiCall(data);
  }
}