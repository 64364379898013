import { useEffect, useRef, useState } from "react";
import { AppSession } from "../utils/store";
import config from '../../../config.json';
import { useExternalScript } from "../utils/useExternalScript";
import { Loader } from "./Loader";

const UnityApp = props => {

  const canvasRef = useRef(undefined);

  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState(0);

  const unityConfig = config.unity;
  const {
    buildUrl,
    loaderUrl,
    dataUrl,
    frameworkUrl,
    codeUrl,
    streamingAssetsUrl
  } = unityConfig;

  const state = useExternalScript(buildUrl + loaderUrl);

  useEffect(() => {
    if (AppSession.unityApp) return;
    if (state === "ready" && !loaded) {
      setLoaded(true);
      try {
        setTimeout(() => {
          createUnityInstance(canvasRef.current, {
            // devicePixelRatio: window.devicePixelRatio,
            dataUrl: buildUrl + dataUrl,
            frameworkUrl: buildUrl + frameworkUrl,
            codeUrl: buildUrl + codeUrl,
            streamingAssetsUrl: streamingAssetsUrl,
            companyName: "PDANYC",
            productName: "CatGPT",
            productVersion: "v0.8",
            // matchWebGLToCanvasSize: false, // Uncomment this to separately control WebGL canvas render size and DOM element size.
            // devicePixelRatio: 1, // Uncomment this to override low DPI rendering on high DPI displays.
          }, (progress) => {
            setProgress(progress);
          }).then((unityInstance) => {
            AppSession.unityApp = unityInstance;
            window.UnityApp = unityInstance;
            setVisible(true);
            unityInstance.SendMessage('JSListener', 'ReceiveBrightness', 'DIM');
            if (gtag) gtag('event', 'unity-loaded');
            const event = new Event('UnityLoaded');
            window.dispatchEvent(event);
          }).catch((message) => {
            alert(message);
          });
        }, 200);
      } catch (e) { }
    }
  }, [state]);

  return <div id="unity-container" className={`unity${visible ? ' unity--loaded' : ''}`}>
    <canvas ref={canvasRef} className={`unity__canvas`} id="unity-canvas" width="480" height="300"></canvas>
    <Loader loaded={progress === 1}>{Math.round(progress * 100)}%</Loader>
  </div >
}
export default UnityApp;