import React from "react";
import { EVENT_TYPES, trackEvent } from "../utils/analytics";
import { typeIn } from "../utils/animation";
import { go } from "../utils/navigate";
import { UserSession } from "../utils/store";
import AbstractView from "./AbstractView"

export const PLACEHOLDERS = {
  name: 'your name',
  catName: 'your cat’s name'
}

export default class Formality extends AbstractView {

  state = {
    name: '',
    catName: '',
    focuesInput: undefined
  }

  constructor(){
    super();
    this.section = React.createRef(undefined);
  }

  componentDidMount() {
    const h3 = this.section.current.querySelector('.formality__text h3');
    typeIn(h3);
  }

  handleBlur = key => {
    UserSession.formality[key] = this.state[key];
  }

  handlefocus = key => {
    this.setState({ focuesInput: key });
  }

  isValid() {
    const answered = Object
      .keys(this.state)
      .map(key => (this.state[key]))
      .filter(value => value);
    return answered.length === 2;
  }

  render() {
    return <section className="formality" ref={this.section}>
      <h5>tell us about your date</h5>
      <div className="formality__wrapper">
        <div className="formality__text">
          <h3>and<br />
            finally</h3>
        </div>
        <div className="formality__form">
          <ul className="formality__form-list">
            {Object.keys(PLACEHOLDERS).map(key => (
              <input
                key={key}
                type='text'
                className="input"
                // placeholder={PLACEHOLDERS[key]}
                placeholder={this.state.focusInput !== key && PLACEHOLDERS[key]}
                onBlur={e => { this.handleBlur(key); }}
                onChange={(e) => {
                  this.setState({ [key]: e.currentTarget.value })
                }}
                value={this.state[key]}
              />
            ))}
          </ul>
          <div className="controls">
            <button
              onClick={e => {
                const { name, catName } = this.state;
                trackEvent(EVENT_TYPES.CONFIG, {
                  type: 'formality',
                  name, catName
                });
                go('chat')
              }}
              className={`button ${!this.isValid() && 'button--disabled'}`}>next</button>
          </div>
        </div>
      </div>
    </section>
  }
}