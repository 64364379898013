import { useEffect, useState } from "react";
import Close from "../../assets/close.svg"
import { EVENT_TYPES } from "../utils/analytics";
import { UserSession } from "../utils/store";
import { COLORS } from "./Appearence";
import Badge from '../../assets/heart-badge.svg'
import { PERSONALITIES } from "./Personality";
import RadioHeart from '../../assets/radio-heart.svg';
import { PLACEHOLDERS } from "./Formality";

const Customize = props => {

  const [currentColor, setCurrentColor] = useState(UserSession.color);
  const [currentPersonality, setPersonality] = useState(UserSession.personality);
  const [inputs, setInputs] = useState({
    name: UserSession.name,
    catName: UserSession.catName
  });

  useEffect(() => {
    if (props.open) {
      setCurrentColor(UserSession.color);
      setPersonality(UserSession.personality);
    }
  }, [props.open])

  const handleSwatchSelect = colorCode => {
    const color = COLORS[colorCode];
    if (color === currentColor) {
      setCurrentColor(undefined);
    } else {
      setCurrentColor(colorCode);
      // UserSession.color = colorCode;
    }
  }

  const handlePersonalityChange = e => {
    const personality = e.currentTarget.value;
    setPersonality(personality);
    // UserSession.personality = personality;
  }

  const handleBlur = e => {}

  return <div className={`customize ${!props.open ? 'customize--closed' : ''}`}>
    <div className="customize__wrapper">
      <div className="customize__color">
        <span className="customize__label">color</span>
        <div className="customize__colors">

          <ul className="customize__colors-list">
            {Object.keys(COLORS).map(color => (
              <li key={color} onClick={(e) => {
                handleSwatchSelect(color);
              }} className={`customize__swatch customize__swatch--${COLORS[color]} ${color === currentColor && 'customize__swatch--selected'}`}>
                <Badge />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="customize__personality">
        <div className="customize__personality-wrapper">
        <span className="customize__label">personality</span>
        <ul className="personality__choices">
          {Object.keys(PERSONALITIES).map(personality => (
            <li key={personality} className="personality__choice">
              <input
                onChange={handlePersonalityChange}
                type={'radio'}
                name='personality'
                checked={currentPersonality === PERSONALITIES[personality]}
                value={PERSONALITIES[personality]}></input>
              <div className="personality__heart-container">
              <RadioHeart className="personality__heart" />
              </div>
              <label onClick={e => {
                // this.setState({ personality: PERSONALITIES[personality] })
                setPersonality(PERSONALITIES[personality])
                // UserSession.personality = PERSONALITIES[personality];
              }} htmlFor={PERSONALITIES[personality]}>{PERSONALITIES[personality]}</label>
            </li>
          ))}
        </ul>
        </div>
      </div>
      <div className="customize__names">
        <div className="formality__form">
          <ul className="formality__form-list">
            {Object.keys(PLACEHOLDERS).map(key => {
              return <input
                key={key}
                type='text'
                className="input"
                placeholder={PLACEHOLDERS[key]}
                onBlur={e => { handleBlur(key); }}
                onChange={(e) => {
                  setInputs({ ...inputs, [key]: e.currentTarget.value })
                }}
                value={inputs[key]}
              />
              })}
          </ul>
          <button onClick={() => {
            const event = new CustomEvent('customizechange', { detail: {
              open: props.open !== true,
              color: currentColor,
              personality: currentPersonality,
              name: inputs.name,
              catName: inputs.catName,
            } });
            window.dispatchEvent(event);
          }} className="button">submit</button>
        </div>
      </div>
      <a href="#" className="customize__close" onClick={e => {
        e.preventDefault();
        const event = new CustomEvent('customizechange', { detail: {
          open: props.open !== true,
          // color: currentColor,
          // personality: currentPersonality,
          // name: inputs.name,
          // catName: inputs.catName,
         } });
        window.dispatchEvent(event);
      }}>
        <Close />
      </a>
    </div>
  </div>
}

export default Customize;