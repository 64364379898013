import AbstractView from "./AbstractView"
import Title from '../../assets/main-title.svg'
import { go } from "../utils/navigate";
import { AppSession, UserSession } from "../utils/store";
import config from '../../../config.json';

export default class Home extends AbstractView {

  state = {
    ready: false
  }

  handleClick = () => {
    // UserSession.hasClikedIn = true;
    // AppSession.audioPlayer.play();
    // go('appearance');
  }

  componentDidMount() {
    // gsap.fromTo('.home__header',
    // {opacity: 0},
    // {opacity: 1, duration: 2, ease:'none', delay:1 });

    const onComplete = () => {
      go('chat');
    }

    window.addEventListener('UnityLoaded', () => {
      this.setState({ ready: true })
      // gsap.to('.home__header',
      //   { opacity: 0, duration: 2, ease: 'none', onComplete });
    });

    if (AppSession.unityApp) {
      this.setState({ ready: true })
      //   gsap.to('.home__header',
      //     { opacity: 0, duration: 2, ease: 'none', delay: 3, onComplete });
    }
  }

  render() {
    if (window.gtag) window.gtag('event', 'site-mode', config.mode);
    return <section className={`home`}>
      <div className="home__header" onClick={this.handleClick}>
        <Title />
        <p>an AI experiment</p>
        <button onClick={() => {
          gsap.to('.home__header',
            {
              opacity: 0, duration: 0.3, ease: 'none', onComplete: () => {
                go('chat')
              }
            });
        }} className={`button ${this.state.ready ? 'button--visible' : ''}`}>meow</button>
      </div>
      {config.mode === 'COMING_SOON' && <span className="home__message">coming soon! your AI cat will go live<br />in the next few days.</span>}
      {config.mode === 'OUT' && <span className="home__message">damn! we’ve hit our user limit.<br /><a href={config.fundraiserURL} target="_blank">click here</a> to keep dateyourcat.AI alive.</span>}
      {/* { config.mode === 'APP' && <button onClick={this.handleClick} className="button">meow</button> } */}
      {/* <span className="home__legal">Powered by <a href="https://openai.com/blog/chatgpt/" target='_blank'>CatGPT</a> & <a href="https://pda.nyc/" target='_blank'>PDA</a>.</span> */}
    </section>
  }
}