import AbstractView from "./AbstractView"
// import Heart from '../../assets/heart.svg';
// import EnjoyYourMeal from '../../assets/enjoy_your_date.svg';
// import HeartBadge from '../../assets/heart-badge.svg';
import React, { useRef } from 'react';
import { chat, dialogs } from "../utils/api";
import { AppSession, UserSession } from "../utils/store";
// import { DEFAULT_VOLUME } from "../components/AudioPlayer";
import config from '../../../config.json';
import { typeIn } from "../utils/animation";
import { shuffle } from "../utils/array";
// import { randomNumber } from "../utils/number";
import { EVENT_TYPES, trackEvent } from "../utils/analytics";
// import { promiseTimeout } from '../utils/promise';
import { pickRandom } from "..";
import { PERSONALITIES } from "./Personality";
import { COLORS } from "./Appearence";

const SECONDS_TO_SLEEP = 90;
const SECONDS_TO_IDLE = 60;

export const getDialog = (personality) => {
  const { catName, name } = UserSession;
  let suffix = (catName.length > 0 && name.length > 0)
    ? 'all'
    : (catName.length > 0)
      ? 'cat'
      : (name.length > 0)
        ? 'user'
        : 'default';
  return dialogs[`${personality}-${suffix}`];
}

const normalize = dialog => {
  dialog = dialog.replaceAll('CAT:', '')
    .replaceAll('CAT :', '')
    .replaceAll(/\[(NEUTRAL|POSITIVE|NEGATIVE)\]/g, '');
  return dialog;
}


const respondToSentiment = dialog => {
  const regex = new RegExp(/CAT:(.*)+\[(NEUTRAL|POSITIVE|NEGATIVE)\]/gm);
  const res = regex.exec(dialog);
  if (res) {
    AppSession.unityApp.SendMessage('JSListener', 'ReceiveSentiment', res[2]);
    trackEvent(EVENT_TYPES.CAT, {
      sentiment: res[2],
    });
  }
}
/* */
/*
const HeartButton = (props) => {

  const heartBadge = useRef(this);
  const container = useRef(this);

  const onClick = () => {
    if (props.onClick) props.onClick();

    const svg = container.current.querySelector('svg');

    svg.classList.add("active");
    const tl = gsap.timeline({ repeat: 1 });
    tl.add("main", 0);

    tl.to(
      svg,
      {
        scale: 1.2,
        duration: 0.4,
        ease: "power4.inout"
      },
      "main+=0"
    );

    tl.to(
      svg,
      {
        scale: 1,
        duration: 0.3,
        ease: "power4.out"
      },
      "main+=0.2"
    );

    for (var i = 0; i < 9; i++) {
      const tl = gsap.timeline({
        onComplete: () => {
          heartBadge.current.removeChild(clone);
          svg.classList.remove("active");
        }
      });
      tl.add("float", 0);

      const clone = svg.cloneNode(true);

      clone.classList.add("animating");
      clone.classList.remove("active");
      heartBadge.current.appendChild(clone);
      const delay = 0.1 * i;
      tl.to(
        clone,
        {
          y: -randomNumber(600, 1200),
          delay,
          duration: 1
        },
        "float+=0"
      );
      tl.to(
        clone,
        {
          x: -randomNumber(-50, 50),
          delay,
          duration: 0.5,
          yoyo: true,
          repeat: 2
        },
        "float+=0"
      );
      // tl.to(
      //   clone,
      //   {
      //     rotateY: 360,
      //     delay,
      //     duration: 1
      //   },
      //   "float+=0"
      // );
      tl.to(
        clone,
        {
          opacity: 0,
          delay: 1 + delay - 0.4,
          duration: 0.4
        },
        "float+=0"
      );
    }
  }

  return <button ref={heartBadge} disabled={!props.isActive} className="input__submit" onClick={onClick}>
    <div className="heart-container" ref={container}>
      <HeartBadge className={props.isActive ? 'active' : ''} />
    </div>
  </button>
}*/

export default class Chat extends AbstractView {

  state = {
    cat: '',
    user: '',
    idle: true,
    hasSentError: false,
    isCatSleeping: false,
    isCustomizeOpen: false,
    connected: undefined,
    disabled: false,
    convoHasStarted: false,
    prevMessage: undefined,
  }

  constructor(props) {
    super(props);
    this.chatRef_ = React.createRef(this);
    this.inputWrapperRef_ = React.createRef(this);
    this.headerRef_ = React.createRef(this);
    this.formalityRef_ = React.createRef(this);
    this.chatTextRef_ = React.createRef(this);
    this.idleTimeoutID_ = undefined;
    this.sleepTimeoutID_ = undefined;
    this.catInterruptTimeoutID_ = undefined;

    const resetDialog = personality => new Promise((resolve) => {
      const dialog = getDialog(personality).split('\n');
      respondToSentiment(dialog[dialog.length - 1]);
      let cat = normalize(dialog[dialog.length - 1]);
      this.setState({ cat, user: '' });
      UserSession.personality = personality;
      return chat.connect();
    });

    window.addEventListener('customizechange', (e) => {
      this.setState({ isCustomizeOpen: e.detail.open })
      if (e.detail.open) return;

      const { name, catName, color, personality } = e.detail;
      UserSession.catName = catName || UserSession.catName;
      UserSession.name = name || UserSession.name;
      // UserSession.color = color || UserSession.color;

      let time = 0;

      if (color !== UserSession.color) {
        AppSession.unityApp.SendMessage('JSListener', 'ReceiveCatStatus', 'EXIT');
        time = 5000;
        setTimeout(() => {
          AppSession.unityApp.SendMessage('JSListener', 'ReceiveMaterial', color);
          UserSession.color = color;
        }, time);
        time += 200;
        setTimeout(() => {
          AppSession.unityApp.SendMessage('JSListener', 'ReceiveCatStatus', 'ENTER');
        }, time);
        time += 2000;
      }

      if (personality && personality !== UserSession.personality) {
        this.setState({ cat: '' });
        setTimeout(() => {
          resetDialog(personality);
          UserSession.personality = personality;
        }, time)
      };
    })
  }

  connect = () => {
    chat.connect().then(data => {

      if (data.error) {
        trackEvent(EVENT_TYPES.ERROR, {
          error: 'user could not connect to davinci.'
        });
        this.setState({ connected: false });
      } else {

        const color = pickRandom(COLORS);
        const personality = pickRandom(PERSONALITIES, true);

        if (AppSession.unityApp) {
          AppSession.unityApp.SendMessage('JSListener', 'ReceiveMaterial', color);
          AppSession.unityApp.SendMessage('JSListener', 'ReceiveBrightness', 'BRIGHT');
          setTimeout(() => {
            AppSession.unityApp.SendMessage('JSListener', 'ReceiveCatStatus', 'ENTER');
            setTimeout(() => {
              this.setState({ connected: true });
              gsap.set(window.document.body, { z: 1 })
              const dialog = getDialog(personality).split('\n');
              respondToSentiment(dialog[dialog.length - 1]);
              let cat = normalize(dialog[dialog.length - 1]);
              this.setState({ cat });
              const event = new Event('CatEntered');
              window.dispatchEvent(event);
            }, 2000)
          }, 500)

        }
        else if (data.choices[0].text) {
          UserSession.chat.messages += '\nUSER:' + data.choices[0].text;
        }

        // console.log(UserSession.chat.message)
      }
    });
  }

  userInteracted = () => {

    if (this.state.isCatSleeping) {
      this.setState({ isCatSleeping: false });
      AppSession.unityApp.SendMessage('JSListener', 'ReceiveCatStatus', 'AWAKE');
      trackEvent(EVENT_TYPES.CAT, {
        action: 'cat woke up.'
      })
    }

    this.setState({ idle: false });
    clearTimeout(this.idleTimeoutID_);
    clearTimeout(this.sleepTimeoutID_);
    clearTimeout(this.catInterruptTimeoutID_);

    // start idle timeout
    this.idleTimeoutID_ = setTimeout(() => {

      this.catInterruptTimeoutID_ = setTimeout(() => {
        const chat = shuffle(config.chat)[0];
        AppSession.unityApp.SendMessage('JSListener', 'ReceiveSentiment', 'NEUTRAL');
        trackEvent(EVENT_TYPES.CAT, {
          action: 'cat is bored.'
        })
        this.setState({ cat: chat })
      }, SECONDS_TO_IDLE * 1000);

      this.sleepTimeoutID_ = setTimeout(() => {
        this.setState({ isCatSleeping: true });
        AppSession.unityApp.SendMessage('JSListener', 'ReceiveCatStatus', 'ASLEEP');
        trackEvent(EVENT_TYPES.CAT, {
          action: 'cat fell asleep.'
        })
      }, SECONDS_TO_SLEEP * 1000);
    }, 5000);

  }

  handleSend = () => {
    if (this.state.user.length === 0) return;

    this.setState({ prevMessage: this.state.user })

    this.userInteracted();
    chat.send(this.state.user).then(data => {
      if (data.error) {
        this.setState({ hasSentError: true });
        trackEvent(EVENT_TYPES.ERROR, {
          error: 'could not send message.'
        });
        setTimeout(() => {
          this.setState({ hasSentError: false });
        }, 2000);
      } else {
        UserSession.chat.messages += ' ' + data.choices[0].text;
        this.setState({ cat: normalize(data.choices[0].text) });
        trackEvent(EVENT_TYPES.CHAT, {
          dialog: UserSession.chat.messages,
          session: 'active'
        });
        // console.log(UserSession.chat.messages)
        respondToSentiment(data.choices[0].text);
        if (data.usage.total_tokens >= config.token_limit) {
          this.setState({ disabled: true });
          AppSession.unityApp.SendMessage('JSListener', 'ReceiveCatStatus', 'EXIT')
          trackEvent(EVENT_TYPES.CHAT, {
            dialog: UserSession.chat.messages,
            session: 'over'
          });
        }
      }
    });
    this.setState({ user: '', convoHasStarted: true });
  }

  handleChange = (e) => {
    this.userInteracted();

    if (this.state.hasSentError) {
      this.setState({ hasSentError: false });
    }

    if (this.state.user.length < 244) {
      this.setState({ user: e.currentTarget.value })
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (!AppSession.unityApp) {
        window.addEventListener('UnityLoaded', this.connect);
      } else {
        this.connect();
      }
    }, 2000);
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.connected === undefined && this.state.connected) {
      if (this.formalityRef_.current) {
        const el = this.formalityRef_.current;
        gsap.to(el.querySelectorAll('svg, span'), { opacity: 0 });
      }

      if (this.inputWrapperRef_.current) {
        this.inputWrapperRef_.current.classList.add('input__wrapper--connected');
      }
      this.userInteracted();
    }

    if (prevState.cat !== this.state.cat && this.chatTextRef_.current) {
      typeIn(this.chatTextRef_.current);
    }


  }



  render() {

    console.log(this.state.connected)

    return <section ref={this.chatRef_} className={`chat${this.state.connected ? ' chat--connected' : ''} ${this.state.isCustomizeOpen ? 'chat--hide-ui' : ''}`}>
      {this.state.connected === false && <div className="chat__connect-error">
        <h3>our bad! we’re having trouble connecting to your cat_</h3>
        <div><button onClick={this.connect} className="button">Try again</button></div>
      </div>}
      {this.state.connected !== false &&
        <div className="chat__response">
          <span ref={this.chatTextRef_} className="chat__response-text">{this.state.cat}</span>
        </div>}
      {(!this.state.disabled && this.state.connected !== false) &&
        <div className="chat__bottom-ui">
          <div className="chat__error" style={{ opacity: this.state.hasSentError ? 1 : 0 }}>
            <p>your message failed to send. sorry!<br />
              try again to chat with your cat.</p>
          </div>
          {this.state.prevMessage && <div className="chat__prevMessage">{this.state.prevMessage}</div>}
          <form onSubmit={(e) => {
            e.preventDefault();
            this.handleSend();
            this.inputWrapperRef_.current.querySelector('input').blur()
          }}>
            <div ref={this.inputWrapperRef_} className="input__wrapper">
              <input
                value={this.state.user}
                type="text"
                placeholder="type here"
                onChange={this.handleChange}
                onKeyUp={e => {
                  if (e.code === 'Enter') this.handleSend();
                }} />
              <span className="input__char-count">{this.state.user.length}/244</span>
              {/* <HeartButton isActive={this.state.user.length > 0} onClick={() => {
              this.handleSend();
              this.userInteracted();
            }} /> */}
              <button className="input__submit" onClick={() => {
                this.handleSend();
                this.userInteracted();
              }}>
                😻
                {/* <HeartBadge className={this.state.user.length > 0 ? 'active' : ''} /> */}
              </button>
            </div>
          </form>
        </div>
      }
    </section>
  }
}