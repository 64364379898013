import AbstractView from "./AbstractView";
import RadioHeart from '../../assets/radio-heart.svg';
import { go } from "../utils/navigate";
import { UserSession } from '../utils/store';
import React from "react";
import { typeIn } from "../utils/animation";
import { EVENT_TYPES, trackEvent } from "../utils/analytics";

export const PERSONALITIES = {
  SKITTISH: 'skittish',
  AFFECTIONATE: 'affectionate',
  HIGH_MAINTENANCE: 'high maintenance',
  MISCHIEVOUS: 'mischievous',
}

export default class Personality extends AbstractView {

  state = {
    personality: undefined
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.section = React.createRef();
  }

  componentDidMount() {
    const h3 = this.section.current.querySelector('.personality__text h3');
    typeIn(h3);
  }

  handleChange(e){
    const personality = e.currentTarget.value;
    this.setState({personality});
    UserSession.personality = personality;
  }

  render() {
    return <section className="personality" ref={this.section}>
      <h5>tell us about your date</h5>
      <div className="personality__wrapper">
        <div className="personality__text">
          <h3>pawsome!<br />is your<br />cat?</h3>
        </div>
        <div>
          <ul className="personality__choices">
            {Object.keys(PERSONALITIES).map(personality => (
              <li key={personality} className="personality__choice">
                <input onChange={this.handleChange}
                  type={'radio'}
                  name='personality'
                  checked={this.state.personality === PERSONALITIES[personality]}
                  value={PERSONALITIES[personality]}></input>
                <RadioHeart className="personality__heart" />
                <label onClick={e => {
                  this.setState({ personality: PERSONALITIES[personality] })
                  UserSession.personality = PERSONALITIES[personality];
                }} htmlFor={PERSONALITIES[personality]}>{PERSONALITIES[personality]}</label>
              </li>
            ))}
          </ul>
          <div className="controls">
            <button
              onClick={e => {
                trackEvent(EVENT_TYPES.CONFIG, {
                  type: 'personality',
                  personality: this.state.personality
                });
                go('details')
              }}
              className={`button ${this.state.personality === undefined && 'button--disabled'}`}>next</button>
          </div>
        </div>
      </div>
    </section>
  }
}