import { COLORS } from "../views/Appearence";

export const UserSession = {
  hasClikedIn: false,
  color: '',
  personality: '',
  name: '',
  catName: '',
  chat: {
    messages: ''
  },
  hasDetails: () => {
    return Object.keys(UserSession.details).map(key => {
      return UserSession.details[key]
    }).filter(key => (key.length > 0)).length === 4;
  },
  hasFormailty: () => {
    return Object.keys(UserSession.formality).map(key => {
      return UserSession.formality[key]
    }).filter(key => (key.length > 0)).length === 2;
  },
  hasPersonailty: () => {
    return UserSession.personality.length > 0;
  },
  hasAppearance: () => {
    return UserSession.color !== '';
  },
};

export const AppSession = {
  clicks: 0,
  audioPlayer: undefined
};