import Close from "../../assets/close.svg";
import config from '../../../config.json';

const PrivacyPolicy = props => {
  return <section className={`pp${props.open ? ` pp--open` : ''}`}>
    <a href="#" className="pp__close" onClick={e => {
      e.preventDefault();
      if (gtag) gtag('event', 'pp-close');
      const event = new CustomEvent('ppchange', { detail: { open: props.open !== true } });
      window.dispatchEvent(event);
    }}>
      <Close />
    </a>
    <div className="pp__text">
      {config.pp.split('\n').map((str, index) => (<p key={index} dangerouslySetInnerHTML={{ __html: str }} />))}
    </div>
  </section>
}

export default PrivacyPolicy